section {
  background-color: var(--white);
}
.image img {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  object-fit: cover;
}

.image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  /* background: rgb(244, 104, 28, 0.8); */
  opacity: 0.9;
  z-index: 1;
}

section .home-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 1rem;
}
.Home-head_text {
  text-align: center;
  font-size: 3rem;
  font-family: var(--sans-font);
  font-weight: 500;
  line-height: 1.15;
  color: var(--white);
}

.desc {
  font-size: 1.225rem;
  font-family: var(--lato-font);
  font-weight: 300;
  line-height: 1.75rem;
  max-width: 42rem /* 672px */;
  color: var(--white);
  /* font-weight: 500; */
  text-align: center;
}

.outline_btn {
  border-radius: 9999px;
  border: 1px solid #fff;
  background: transparent;
  padding: 0.375rem 1.25rem 0.375rem 1.25rem;
  color: var(--white);
  transition: all 0.3s;
  text-align: center;
  font-size: 0.875rem;
  font-family: var(--lato-font);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -218px;
  cursor: pointer;
}

.outline_btn:hover {
  background: var(--orange);
  border: 1px solid var(--orange);
  color: var(--white);
  outline: 0;
}

.outline_btn .icon {
  padding: 2px 5px 0 0;
  width: 25px;
  height: 25px;
}

.relative span::after {
  content: "";
  display: block;
  width: 1px;
  height: 9rem;
  background-color: #fff;
  position: absolute;
  bottom: -150px;
}

@media (max-width: 640px) {
  .Home-head_text {
    font-size: 2.5rem;
  }
}
