.contact {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  background-color: var(--white);
  padding-top: 5rem;
  background-image: url("../../assets/LandingImages/landingImg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
}

.contact::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0.9;
  z-index: 1;
}

.contact .popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 300px;
  padding: 2rem;
  border-radius: 10px;
  background: var(--white);
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset,
    rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;
  z-index: 21;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.popup.close {
  display: none;
}

.popup a {
  text-decoration: none;
  color: var(--black);
  font-family: var(--lato-font);
}

.popup a:hover {
  text-decoration: underline;
}

.popup span {
  position: absolute;
  top: 10%;
  right: 5%;
  font-size: 1.5rem;
  z-index: 1;
  color: var(--black);
  cursor: pointer;
  font-family: var(--lato-font);
}

.contact h1 {
  display: inline-block;
  font-size: var(--header);
  text-align: center;
  font-weight: 600;
  font-family: var(--lato-font);
  color: var(--white);
  position: relative;
  margin-bottom: 20px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
}

.contact .content {
  padding-top: 2rem;
  max-width: 1020px;
  margin: 0 auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  gap: 3rem;
  position: relative;
  z-index: 20;
}

.content a {
  text-decoration: none;
}

.content .box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content .box h2 {
  font-size: var(--header2);
  font-family: var(--sans-font);
  font-weight: 300;
  color: var(--white);
}
.content .box span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: var(--sans-font);
  font-weight: 300;
  color: var(--white);
}

.content .box span .icon {
  width: 20px;
}

.contact-btn {
  border: 1px solid var(--orange);
  background-color: var(--orange);
  border-radius: 2px;
  padding: 0.575rem 1.25rem 0.575rem 1.25rem;
  color: var(--white);
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-family: var(--lato-font);
  transition: all 0.2s ease-in-out;
}

.contact-btn:hover {
  background-color: transparent;
  color: var(--white);
  border: 1px solid var(--white);
}
