.about {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  background-color: var(--white);
  padding-top: 5rem;
  background: linear-gradient(
    177deg,
    rgba(244, 104, 28, 1) 8%,
    rgba(244, 104, 28, 1) 58%,
    rgba(245, 117, 48, 1) 88%
  );
}

.about h1 {
  display: inline-block;
  font-size: var(--header);
  text-align: center;
  font-weight: 600;
  font-family: var(--lato-font);
  color: var(--white);
  position: relative;
  margin-bottom: 20px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* .about h1::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 3px;
  width: 100%;
  background-color: var(--white);
} */

.about-us {
  max-width: 1020px;
  margin: 0 auto;
  display: flex;
  padding: 1.5rem;
  gap: 3rem;
}

.about-us .left-side {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
  justify-content: start;
}

.about-us .left-side p {
  font-size: var(--para);
  color: var(--white);
  font-family: var(--sans-font);
  line-height: 1.75rem;
  max-width: 42rem;
  font-weight: 500;
  text-align: start;
}

.about-us .right-side img {
  width: 100%;
  height: 300px;
}

.about-us .left-side .social-links {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.about-us .left-side .social-links span {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: var(--sans-font);
  color: var(--white);
}

.about-us .left-side .social-links .icon {
  font-size: 1.2rem;
  cursor: pointer;
  color: var(--white);
  transition: all 0.2s ease-in;
}

.about-us .left-side .social-links .icon:hover {
  transform: scale(1.2);
}

@media screen and (max-width: 950px) {
  .about-us {
    flex-direction: column;
    padding: 1.5rem;
  }
  .about-us .right-side img {
    width: 100%;
    height: auto;
  }
}
