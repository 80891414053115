nav {
  position: fixed;
  width: 100%;
  z-index: 30;
}
.top-header {
  height: 30px;
  background: var(--orange);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
}

.top-header h2 {
  color: var(--white);
  font-weight: 500;
  font-size: var(--header2);
  font-family: var(--sans-font);
}

.top-header .left-side {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.top-header .icon {
  width: 20px;
  height: 20px;
  color: var(--white);
}

.top-header a {
  text-decoration: none;
  font-weight: 500;
  font-family: var(--sans-font);
  font-size: var(--header2);
  color: var(--white);
}

.top-header a:hover {
  text-decoration: underline;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  padding: 5px 0;
  padding-left: 12px;
  padding-right: 12px;
  position: fixed;
  transition: all 0.2s ease-in;
  z-index: 30;
  background: var(--white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  text-decoration: none;
  cursor: pointer;
  mix-blend-mode: multiply;
}

.logo img {
  width: 100%;
  height: 65px;
}

.desktop-nav {
  display: none;
}

.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-links li {
  list-style: none;
  position: relative;
}

.nav-links li a {
  text-decoration: none;
  color: var(--black);
  font-family: var(--lato-font);
  font-weight: 300;
  font-size: 1.2rem;
  cursor: pointer;
}

.nav-links li a:hover {
  color: var(--orange);
}

/* .nav-links li::after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: var(--orange);
  position: absolute;
  left: 0;
  bottom: -8px;
  opacity: 0;
  transition: all 0.2s ease-in;
  pointer-events: none;
} */

.nav-links li.color::after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: var(--black);
  position: absolute;
  left: 0;
  bottom: -8px;
  opacity: 0;
  transition: all 0.2s ease-in;
  pointer-events: none;
}

.nav-links li:hover::after,
.nav-links li:focus::after {
  opacity: 1;
}

.mobile-nav {
  display: flex;
  position: relative;
}

.nav-toggle {
  display: flex;
}

.hamb {
  cursor: pointer;
  color: var(--black);
  width: 27px;
  height: 27px;
}

.dropdown {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 3rem;
  width: 100%;
  min-width: 210px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.25rem;
  color: black;
}

.dropdown a {
  text-decoration: none;
  color: var(--black);
  padding: 1rem;
  font-size: 1.2rem;
  cursor: pointer;
}

.dropdown a:hover {
  color: var(--orange);
}

.mobile-nav.active .dropdown {
  display: flex;
}

@media screen and (min-width: 650px) {
  .navbar {
    padding-left: 24px;
    padding-right: 24px;
    justify-content: space-around;
  }
  .logo img {
    width: 100%;
    height: 75px;
  }
  .desktop-nav {
    display: block;
  }
  .mobile-nav {
    display: none;
  }
}
