.wrapper {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  background-color: var(--white);
  padding-top: 5rem;
}

.wrapper h1 {
  display: inline-block;
  font-size: var(--header);
  text-align: center;
  font-weight: 600;
  font-family: var(--lato-font);
  color: var(--black);
  position: relative;
  margin-bottom: 10px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.wrapper h3 {
  font-size: var(--header2);
  text-align: center;
  font-weight: 600;
  font-family: var(--lato-font);
  color: var(--orange);
}

/* .wrapper h1::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 3px;
  width: 100%;
  background-color: var(--black);
} */

.our-gallary {
  max-width: 1020px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  gap: 3rem;
}

.wrapper .carousel {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: calc((100% / 3) - 12px);
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 16px;
  border-radius: 8px;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.card figure {
  height: 500px;
  overflow: hidden;
}

.card figure img {
  height: 500px;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
  user-select: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.card figure img:hover {
  transform: scale(1.1);
}

.swiper-pagination-bullet-active {
  background-color: var(--orange) !important;
}
