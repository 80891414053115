@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&family=Lato:wght@300;400&family=Montserrat&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  font-size: 85.5%;
}

:root {
  --white: #fff;
  --black: #222;
  --orange: #f4681c;
  --light-brown: #ac9b79;
  --sm-font: 1.4888rem;
  --md-font: 1.99rem;
  --lg-font: 2.4rem;
  --xl-font: 2.989rem;
  --letter-sp: 1px;
  --font-color: #595959;
  --sans-font: "DM Sans", sans-serif;
  --lato-font: "Montserrat", sans-serif;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

main {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 1250px;
  margin: 0 auto;
}

.desc-p {
  font-size: 1.025rem;
  line-height: 1.75rem;
  max-width: 42rem;
  color: #000;
  font-weight: 500;
  text-align: start;
}

:root {
  --header: 2rem;
  --header2: 1.011rem;
  --header3: 1.311rem;
  --para: 1.125rem;
}

.btn {
  border: 1px solid var(--orange);
  background-color: var(--orange);
  border-radius: 2px;
  padding: 0.575rem 1.25rem 0.575rem 1.25rem;
  color: var(--white);
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-family: var(--lato-font);
  transition: all 0.2s ease-in-out;
}

.btn:hover {
  background-color: transparent;
  color: var(--black);
  border: 1px solid var(--black);
}

@media screen and (min-width: 640px) {
  main {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  html {
    font-size: 89.5%;
  }
  :root {
    --header: 2.6rem;
    --header2: 1.4rem;
    --header3: 1.811rem;
  }
}

@media screen and (max-width: 330px) {
  html {
    font-size: 70.5%;
  }
}
