footer {
  /* background-color: #f0f1f8; */
  background: linear-gradient(
    177deg,
    rgba(244, 104, 28, 1) 8%,
    rgba(244, 104, 28, 1) 58%,
    rgba(245, 117, 48, 1) 88%
  );
}

.footer-content {
  position: relative;
  margin: 0 auto;
  max-width: 950px;
  text-align: center;
  gap: 2rem;
  padding: 1rem 2.2rem;
}

.footer-content .footer-content-info {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.footer-content .footer-content-info h3 {
  color: var(--white);
  font-family: var(--lato-font);
}

.footer-content .footer-content-info p {
  font-size: var(--para);
  font-family: var(--sans-font);
  font-weight: 300;
  color: var(--white);
}
.footer-content .footer-content-info .socials {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.footer-content .footer-content-info .socials a .icon {
  color: var(--white);
  width: 20px;
  height: 20px;
}
.footer-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-bottom p {
  font-size: var(--para);
  font-family: var(--sans-font);
  color: var(--white);
}

.footer-bottom a {
  text-decoration: none;
  color: var(--white);
  font-family: var(--sans-font);
}

.footer-bottom a:hover {
  text-decoration: underline;
}
