.service {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  background-color: var(--white);
  padding-top: 5rem;
}

/* .service .top-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.service h1 {
  display: inline-block;
  font-size: var(--header);
  text-align: center;
  font-weight: 600;
  font-family: var(--lato-font);
  color: var(--black);
  position: relative;
  margin-bottom: 20px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.service h3 {
  display: inline-block;
  font-size: var(--header3);
  text-align: center;
  font-weight: 500;
  font-family: var(--lato-font);
  color: var(--black);
  position: relative;
  margin-bottom: 20px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.service p {
  font-size: var(--para);
  color: var(--black);
  line-height: 1.75rem;
  font-family: var(--sans-font);
  font-weight: 500;
  text-align: center;
}

/* .service h1::after,
.service h3::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  height: 3px;
  width: 100%;
  background-color: var(--black);
} */

.service-info {
  max-width: 1020px;
  margin: 0 auto;
  padding-top: 2rem;
}

.service-info .box-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  justify-items: center;
  align-items: center;
  padding: 1.5rem;
}

.service-info .bottom-wrapper {
  padding-top: 2rem;
}

.service-info .box-wrapper .box {
  width: 100%;
  border-radius: 0.5rem;
}

.service-info .box-wrapper .box figure {
  height: 400px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.service-info .box-wrapper .box figure .overlay {
  position: absolute;
  width: 100%;
  height: 400px;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-info .box-wrapper .box figure .overlay h2 {
  font-size: var(--header2);
  color: var(--white);
  font-weight: 500;
  font-family: var(--sans-font);
}

/* Show the overlay when hovering over the figure */
.service-info .box-wrapper .box figure:hover .overlay {
  opacity: 1;
}

.service-info .box-wrapper .box figure img {
  height: 400px;
  width: 100%;
  object-fit: cover;
  user-select: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.service-info .box-wrapper .box .content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 0;
}

.service-info .box-wrapper .box .content a {
  margin-top: 10px;
  text-decoration: none;
}

/* .service-info .box-wrapper .box .content  */

@media screen and (max-width: 950px) {
  .service-info .box-wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }

  .service-info .box-wrapper .box .content {
    padding-top: 0;
  }
}

@media screen and (max-width: 600px) {
  .service-info .box-wrapper {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
